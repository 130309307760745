import axios from './axios';

export const getHeader = () => {
    const header = {
        headers: {
            Auth: 'Basic Auth', 
            Accept: "application/json", 
            'Access-Control-Allow-Origin': '*', 
            'Access-Control-Allow-Credentials': true,
        }
    }
    return header;
}

export const getAuthHeaders = () => {
    const token = 'Bearer '+localStorage.getItem('authToken');
    const header = {
        headers: {
            Authorization: token, 
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*', 
            'Access-Control-Allow-Credentials': true,
            "Content-Type": "application/json"
        }
    }
    return header;
}

export const postSubmitUserLogin= async (postdata) => {
    return await axios.post('/User/SubmitUserLogin', postdata, getAuthHeaders()).then(res => {
        return res;
    })
}

export const opstSendVerifyOTPMibile= async () => {
    return await axios.post('/User/SendVerifyOTPMobile', getAuthHeaders()).then(res => {
        return res;
    })
}

export const opstVerifyMobile= async (otp) => {
    return await axios.post('/User/VerifyMobile?OTP='+otp, getAuthHeaders()).then(res => {
        return res;
    })
}

export const getuserotp= async () => {
    return await axios.get('/User/Send2FAOTP', getAuthHeaders()).then(res => {
        return res;
    })
}

export const getVerify2FAOTP= async (otp) => {
    return await axios.get('/user/Verify2FAOTP?OTP='+otp, getAuthHeaders()).then(res => {
        return res;
    })
}

export const getAuthHeader = () => {
    const token = 'Bearer '+localStorage.getItem('userToken');
    const header = {
        headers: {
            Authorization: token, 
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*', 
            'Access-Control-Allow-Credentials': true,
            "Content-Type": "application/json"
        }
    }
    return header;
}

export const postLogin = async (vlaues) => {
    let email = vlaues.email;
    let password = vlaues.password;
    let twoFactorCode = '';
    let twoFactorRecoveryCode = '';
    return await axios.post('/login', {email,password,twoFactorCode,twoFactorRecoveryCode}, getHeader()).then(res => {
        return res;
    })
}

export const postRegiter = async (vlaues) => {
    return await axios.post('/User', vlaues, getHeader()).then(res => {
        return res;
    })
}

export const postForgotPassword = async (vlaues) => {
    let EmailId = vlaues.email;
    return await axios.post('/user/SendForgetEmail', {EmailId}, getHeader()).then(res => {
        return res;
    })
}

export const postchangePassword = async (EmailId) => {
    return await axios.post('/user/SendForgetEmail', {EmailId}, getHeader()).then(res => {
        return res;
    })
}

export const postResetPassword = async (postdata) => {
    let password = postdata.password;
    let confirmPassword = postdata.confirmPassword;
    let email = postdata.email;
    let token = postdata.token;
    return await axios.post('/user/ChangePassword', {password,confirmPassword,email,token}, getHeader()).then(res => {
        return res;
    })
}

export const postAddParticipantLog = async (postdata) => {

    return await axios.post('/Activity/AddParticipantLog', postdata, getAuthHeader()).then(res => {
        return res;
    })
}

export const postAddCredit = async (postdata) => {
    return await axios.post('/User/AddCredit', postdata, getAuthHeader()).then(res => {
        return res;
    })
}

export const postPaymentWithCitiPay = async (resdata) => {    
    return await axios.post('/Activity/PaymentWithCitiPay', resdata, getAuthHeader()).then(res => {
        return res;
    })
}

export const postUpdateuser = async (userdata) => {    
    return await axios.post('/User/UpdateUser', userdata, getAuthHeader()).then(res => {
        return res;
    })
}

// Get Method
export const getUser= async () => {
    return await axios.get('/User/GetUser', getAuthHeader()).then(res => {
        return res;
    })
}

export const getUserEzoPoint= async () => {
    return await axios.get('/User/GetUserEzoPoint', getAuthHeader()).then(res => {
        return res;
    })
}

export const getLastUserLogin = async () => {
    return await axios.get('/User/GetLastUserLogin', getAuthHeader()).then(res => {
        return res;
    })
}

export const getDashboard = async () => {
    return await axios.get('/Activity/GetPublicDashboard', getAuthHeader()).then(res => {
        return res;
    })
}

export const getShortcodeList = async () => {
    return await axios.get('/Activity/GetShortcodeList', getAuthHeader()).then(res => {
        return res;
    })
}

export const getAllParticipantLog= async () => {
    return await axios.get('/Activity/GetAllParticipantLog', getAuthHeader()).then(res => {
        return res;
    })
}

export const getGetAllPaymentTransactionLog= async () => {
    return await axios.get('/Activity/GetAllPaymentTransactionLog', getAuthHeader()).then(res => {
        return res;
    })
}

export const getCityPayPaymentResponse= async (Identifier) => {
    return await axios.get('/Activity/CityPayPaymentResponse/'+Identifier, getAuthHeader()).then(res => {
        return res;
    })
}

export const getDeletePublicUser= async (id) => {
    return await axios.delete('/user/DeletePublicUser', getAuthHeader()).then(res => {
        return res;
    })
}