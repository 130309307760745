import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
    return(
        <>
          <div id='notfound'>
                <div>
                    <p>404 | Page not Found !!</p>
                    <Link className='btn btn-yellow' to={'/'}>GO TO HOMEPAGE / LOGINPAGE</Link>
                </div>
          </div>
        </>
    );
}
export default Error