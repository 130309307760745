import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import DataTable from "react-data-table-component";

import Header from '../../includes/Header';
import Sibebar from '../../includes/Sibebar';

import { getGetAllPaymentTransactionLog, getUser } from '../../apiservices/ApiServices';
import Loader from '../../components/Loader/Loader';

import { format } from 'date-fns';
import { FaCheckCircle, FaTimesCircle  } from "react-icons/fa";
import axios from 'axios';

function PaymentHistory(props) {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [user, setUser] = useState([]);
  const itemsPerPage = 5;

  
  const convertDateTimeFormat = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return format(date, 'dd-MM-yyyy hh:mm a');
  };

  const statusFormat = (status) => {
     if(status=== 'Approved')
     {
      return (
        <a href='javascript:void(0);' className='sscard green'>
            <FaCheckCircle />
            <span>{status}</span>
        </a>);
     }
     else if (status=== 'Failed')
     {
        return (<a href='javascript:void(0);' className='sscard red'>
            <FaTimesCircle />
            <span>{status}</span>
        </a>);
     }
     else
     {
        return (<a href='javascript:void(0);'>{status ? status : "--"}</a>);
     }
  };



  useEffect(() => {
    if(!localStorage.getItem('userToken'))
    {
      navigate("/");
    }
    else {
      getGetAllPaymentTransactionLog().then((res) => {
        try {
          console.log(res.data)
          setData(res.data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      });

      getUser().then((res) => 
        {
            try {
                setUser(res.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        });

        axios.interceptors.response.use(
          (response) => response,
          (error) => {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem('authToken');
              localStorage.removeItem('userToken');
              navigate('/'); // Redirect to login screen
            }
            return Promise.reject(error);
          }
      );
    }
  }, [navigate])

  const customStyles = {
    rows: {
      style: {
        minHeight: '40px', // override the row height
      },
    },
    headCells: {
      style: {
        padding: '12px 15px',
        background: '#eaecf4',
        fontFamily: 'Poppins-Bold',
        fontSize: '16px',
        color: '#000',
        borderColor: '#e3e6f0',
      },
    },
    cells: {
      style: {
        padding: '12px 15px',
        background: '#fff',
        fontFamily: 'Poppins',
        fontSize: '15px',
        color: '#606060',
        borderColor: '#e3e6f0',
      },
    },
  };


  const columns = [
    {
      name: 'Mobile',
      selector: row => row.mobileNumber,
      sortable: true,
      width: "180px",
    },
    {
      name: 'Transaction Reference',
      selector: row => row.transactionNumber,
      sortable: true,
    },
    {
      name: 'ORZI Credits',
      selector: row => row.amount,
      sortable: true,
      width: "160px",
    },
    {
      name: 'Transaction Type',
      selector: row => row.transactionType,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => statusFormat(row.status),
      sortable: true,
    },
    {
      name: 'Source',
      selector: row => row.source,
      sortable: true,
      width: "150px",
    },
    {
      name: 'Date/Time',
      selector: row => `${convertDateTimeFormat(row.createdOn)}`,
      sortable: true,
      width: "250px",
    },
  ];

  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Get the items for the current page
  const currentItems = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Header UserData={user}/>
      <section id='pagecontent'>
        <div className='pagecontent-box'>
          <Sibebar UserData={user}/>
          <div className='contents'>
            <div className='pagetitle'>
              <h1>Payment History</h1>
            </div>
            <div className='cards tables mt-2' id='paymenth'>
              <div className='cards-hedar'>
                <h4>Payment History</h4>
              </div>
              {isLoading ? <Loader /> :
                <div className='cards-body'>
                  <div className='resdnone-tab'>
                    <DataTable
                      columns={columns}
                      data={data}
                      customStyles={customStyles}
                      pagination
                      {...props}
                    />
                  </div>
                  <div className='tablescards'>
                    {currentItems.map((item, index) => {
                      return (
                        <div className='cardstbs' key={`listitem_${index}`}>
                          <div id='payment' className='cmpdata' style={{ padding: 0 }}>
                            <p><b>Mobile :</b> {item.mobileNumber}</p>
                            <p><b>Transaction Reference :</b> {item.transactionNumber}</p>
                            <p><b>ORZI Credits :</b> {item.amount}</p>
                            <p><b>Transaction Type :</b> {item.transactionType}</p>
                            <p><b>Status :</b> 
                              {item.status=== 'Approved' ? 
                                    <div className='sscard green'>
                                        <FaCheckCircle />
                                        <span>{item.status}</span>
                                    </div> : (
                                    <>
                                    {item.status=== 'Failed' ? 
                                        <div className='sscard red'>
                                        <FaTimesCircle />
                                        <span>{item.status}</span>
                                    </div> : <span>{item.status ? item.status : "--"}</span>}
                                    </>
                                )}
                            </p>
                            <p><b>Source :</b> {item.source}</p>
                            <p><b>Date/Time :</b> {convertDateTimeFormat(item.createdOn)}</p>
                          </div>
                        </div>
                      );
                    })}
                    {data.length > 5 ? (
                      <div className="pagination-controls">
                        <Button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Previous</Button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <Button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>Next</Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PaymentHistory