import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo-inverse.svg';
import Email from '../assets/images/email-verified.gif';

const EmailVerification = () => {

    return (
        <section id='emailVerified'>
            <div className='email-block'>
                <div className='cardsheds'>
                    <div className='logo'>
                        <img src={Logo} alt='logo' title='logo' />
                    </div>
                    <h4>Email Verified</h4>
                </div>
                <p>Your email address was successfully verified.</p>
                <div className='imagesboxs'>
                    <img src={Email} alt='logo' title='logo' />
                </div>
                <div className='btnbox'>
                    <Link to={'/'} className='btn btn-green'>Login</Link>
                </div>
            </div>
        </section>
    );
}

export default EmailVerification