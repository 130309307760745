import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import Main from './pages/Main';
import { getLastUserLogin } from './apiservices/ApiServices';
import { format } from 'date-fns';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Loader from './components/Loader/Loader';



function App() {
  const [recaptchaKey, setRecaptchaKey] = useState(null);

  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('isVarify', false);
        localStorage.setItem('apiUrl', data.apiUrl);
        localStorage.setItem('googleRecaptchaKey', data.googleRecaptchaKey);
        localStorage.setItem('RecaptchaVerifyUrl', data.RecaptchaVerifyUrl);
        localStorage.setItem('otpLehght', data.otpLehght);

        setRecaptchaKey(data.googleRecaptchaKey); // Set the key in state after fetching

        if (data.isInspect === false) {
          document.addEventListener('contextmenu', (e) => e.preventDefault());

          function ctrlShiftKey(e, keyCode) {
            return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
          }

          document.onkeydown = (e) => {
            // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
            if (
              e.keyCode === 123 ||
              ctrlShiftKey(e, 'I') ||
              ctrlShiftKey(e, 'J') ||
              ctrlShiftKey(e, 'C') ||
              (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
            ) {
              return false;
            }
          };
        }
      })
      .catch(error => console.error('Error loading config:', error));
  }, []);

  useEffect(() => {
    setInterval(() => {
      if (!localStorage.getItem('userToken')) {
        // handle user not logged in
      } else {
        getLastUserLogin().then((res) => {
          try {
            let utcDate = res.data.logDate;
            logindate(utcDate);
          } catch (error) {
            console.error(error);
          } finally {
            // final actions if needed
          }
        });
      }
    }, 10000);
  }, []);

  const YourReCaptchaComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState(null);

    useEffect(() => {
      const fetchRecaptchaToken = async () => {
        if (!executeRecaptcha) {
          console.log("Recaptcha is not ready");
          return;
        }
        try {
          const token = await executeRecaptcha('login');
          setToken(token);
          console.log("Recaptcha token received:", token);

          // Call your backend API to verify the token
          verifyRecaptchaToken(token); // Function that sends token to backend

        } catch (error) {
          console.error("Recaptcha token generation failed:", error);
        }
      };

      fetchRecaptchaToken();
    }, [executeRecaptcha]);

    return null; // No UI needed for this component
  };

  const verifyRecaptchaToken = async (token) => {
    try {

      const response = await fetch(localStorage.getItem('RecaptchaVerifyUrl'), {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
          'Accept': 'application/json', 
          'Content-Type': 'application/json',
        },
        body: `{
                "token":"${token}",
                "domain":"public"}`,
      });

      const data = await response.json();
      if (data.success) {
        console.log('reCAPTCHA verification:- ', data);
        localStorage.setItem('isVarify', true);
      } else {
          localStorage.setItem('isVarify', false);
          localStorage.removeItem('userToken');
          localStorage.removeItem('userInfo');
          localStorage.removeItem('userIdentifier');
      }
    } catch (error) {
      console.error('Error verifying reCAPTCHA:', error);
    }
  };



  const logindate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    localStorage.setItem('lastlogin', format(date, 'd MMMM yyyy hh:mma'));
  };

  if (!recaptchaKey) {
    return <Loader />; // Show a loading state until the config is loaded
  }

  return (
    <BrowserRouter>
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
        <YourReCaptchaComponent />
        <Main />
      </GoogleReCaptchaProvider>
    </BrowserRouter>
  );
}

export default App;
