import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/orzilogo.svg";
import { FaEnvelope } from "react-icons/fa";
import { getLastUserLogin } from '../apiservices/ApiServices';
import { format } from 'date-fns';


const Header = (props) => {
    const [logdata, setLogData] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('userToken')) {
            navigate("/");
        }
        else {
            setInterval(() => {
                let lastlogin = localStorage.getItem('lastlogin');
                setLogData(lastlogin);
            }, 2000);

            getLastUserLogin().then((res) => {
                try {
                    let utcDate = res.data.logDate;
                    logindate(utcDate)
                } catch (error) {
                    console.error(error);
                } finally {
                }
            });
            
        }
    }, [navigate])

    const logindate = (dateTimeString) => {
        const date = new Date(dateTimeString);
        localStorage.setItem('lastlogin', format(date, 'd MMMM yyyy hh:mma'));
        setLogData(format(date, 'd MMMM yyyy hh:mma'));
    };


    return (
        <>
            <header id='header'>
                <div className='header-block'>
                    <div className='logo-box'>
                        <img src={Logo} alt='logo' title='logo' />
                    </div>
                    <div className='rightnav'>
                        <ul>
                            <li>
                                <h6>ORZI.App</h6>
                                <p className='lastlogin rdb'>Last login : {logdata}</p>
                            </li>
                            <li className='btns'>
                                <Link className='btn btn-white' to={'/user/contact-us'}><FaEnvelope /> Contact</Link>
                            </li>
                            <li className='btns'>
                                <Link className='btn btn-white' to={'/activity/add-credit'}>Available Credits : {props.UserData.availableCredit}</Link>
                            </li>
                        </ul>
                        <p className='lastlogin'>Last login : {logdata}</p>
                        <div className='profile'>
                            <div className='userinfo'>
                                <p>Welcome</p>
                                <h6>{props.UserData.emailId}</h6>
                            </div>
                        </div>
                    </div>
                    <p className='lastlogin rdb'>Last login : {logdata}</p>
                </div>
            </header>
        </>
    )
}

export default Header