import React, { useEffect, useState } from 'react';


import Header from '../../includes/Header';
import Sibebar from '../../includes/Sibebar';

import { getUser } from '../../apiservices/ApiServices';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ContactUs = () => {
    const [user, setUser] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
     
    useEffect(() => {
        setLoading(true);
        if(!localStorage.getItem('userToken'))
        {
          navigate("/");
        }
        else {
          getUser().then((res) => 
          {
              try {
                  setUser(res.data);
                  setLoading(false);
              } catch (error) {
                  console.error(error);
              } finally {
                  setLoading(false);
              }
          });

          axios.interceptors.response.use(
            (response) => response,
            (error) => {
              if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                localStorage.removeItem('userToken');
                navigate('/'); // Redirect to login screen
              }
              return Promise.reject(error);
            }
          );
        }
      }, [navigate])
    return (
        <>
            <Header UserData={user}/>
            <section id='pagecontent'>
                <div className='pagecontent-box'>
                    <Sibebar UserData={user}/>
                    <div className='contents'>
                        <div className='pagetitle'>
                            <h1>Contact Us</h1>
                        </div>
                        <div className='cards tables mt-2'>
                            <div className='cards-hedar contactus'>
                                <p>Contact us : <a className='links' to={'mailto:support@orzi.co.uk'} target='_blank'>support@orzi.co.uk</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
      )
}

export default ContactUs